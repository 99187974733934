import { combineReducers, configureStore} from '@reduxjs/toolkit';
import authReducer from './authReducer';
import snackBarReducer from './snackBarReducer';
import userReducer from './userReducer';
import artworkReducer from './artworkReducer';
import notificationReducer from './notificationReducer';
import searchReducer from './searchReducer';
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = combineReducers({
    authState: authReducer,
    snackBarState: snackBarReducer,
    userState: userReducer,
    artworkState: artworkReducer,
    notificationState: notificationReducer,
    searchState: searchReducer,
});

const store = configureStore({
    reducer: rootReducer
}, composeWithDevTools);

export default store;