import { authHeader, handleResponse } from "../utils/utils";
import { API_BASE } from "../config/constants";

const login = async (email, password) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  const response = await fetch(`${API_BASE}/auth/login`, requestOptions);
  const user = await handleResponse(response);
  localStorage.setItem("user", JSON.stringify(user.data));
  return user;
};

const logout = () => {
  localStorage.removeItem("user");
};

const getSubmittedArtworks = async () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  const response = fetch(
    `${API_BASE}/users/info/submitted_artworks`,
    requestOptions
  );
  return await handleResponse(response, logout);
};

const getUserById = async (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  const response = fetch(`${API_BASE}/users/${id}`, requestOptions);
  return await handleResponse(response, logout);
};

const checkUserByEmail = async (email) => {
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };
  const response = fetch(`${API_BASE}/users/check_email`, requestOptions);
  return await handleResponse(response);
};

const getUserByUrl = async (url) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  const response = fetch(`${API_BASE}/users/${url}/url`, requestOptions);
  return await handleResponse(response, logout);
};

const getUserArtworks = async (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  const response = fetch(`${API_BASE}/users/${id}/artworks`, requestOptions);
  return await handleResponse(response, logout);
};

const registerUser = async (user) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  const response = fetch(`${API_BASE}/auth/register`, requestOptions);
  return await handleResponse(response, logout);
};

const updateUser = async (userId, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  const response = fetch(`${API_BASE}/users/${userId}`, requestOptions);
  return await handleResponse(response);
};

const allowArtist = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: userId }),
  };

  const response = fetch(`${API_BASE}/users/artist/allow`, requestOptions);
  return await handleResponse(response);
};

const disallowArtist = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: userId }),
  };

  const response = fetch(`${API_BASE}/users/artist/disallow`, requestOptions);
  return await handleResponse(response);
};

const allowSubmit = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: userId }),
  };

  const response = fetch(`${API_BASE}/users/submit/allow`, requestOptions);
  return await handleResponse(response);
};

const disallowSubmit = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: userId }),
  };

  const response = fetch(`${API_BASE}/users/submit/disallow`, requestOptions);
  return await handleResponse(response);
};

const allowPriToken = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: userId }),
  };

  const response = fetch(
    `${API_BASE}/users/privatetoken/allow`,
    requestOptions
  );
  return await handleResponse(response);
};

const purchaseArtworks = async (id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id }),
  };

  const response = fetch(
    `${API_BASE}/payment/create-checkout-session`,
    requestOptions
  );
  return await handleResponse(response);
};

const disallowPriToken = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: userId }),
  };

  const response = fetch(
    `${API_BASE}/users/privatetoken/disallow`,
    requestOptions
  );
  return await handleResponse(response);
};

const resetPwd = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: userId }),
  };

  const response = fetch(`${API_BASE}/users/update/reset_pwd`, requestOptions);
  return await handleResponse(response);
};

const verifyUserId = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: userId }),
  };

  const response = fetch(`${API_BASE}/users/update/verify`, requestOptions);
  return await handleResponse(response);
};

const submitForVote = async (userId, status) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id: userId,
      status,
    }),
  };

  const response = fetch(`${API_BASE}/users/submitForVote`, requestOptions);
  return await handleResponse(response);
};

const deleteUser = async (userId) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const response = fetch(`${API_BASE}/users/${userId}`, requestOptions);
  return await handleResponse(response, logout);
};

const sendInvite = async (email, type) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
      type,
    }),
  };

  const response = fetch(`${API_BASE}/users/invite`, requestOptions);
  return await handleResponse(response);
};

const reset = async (email) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  const response = await fetch(`${API_BASE}/auth/reset`, requestOptions);
  return await handleResponse(response);
};

const resetpwd = async (token, password) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token, password }),
  };

  const response = await fetch(`${API_BASE}/auth/resetpwd`, requestOptions);
  return await handleResponse(response);
};

const verifyUser = async (token) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  };

  const response = await fetch(`${API_BASE}/auth/verify`, requestOptions);
  return await handleResponse(response);
};

const updateVeriffUserId = async (userId, veriffUserId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      userId,
      veriffUserId,
    }),
  };

  const response = fetch(
    `${API_BASE}/users/update/veriffUserId`,
    requestOptions
  );
  return await handleResponse(response);
};

const updateTooltip = async (type, userId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      type,
      userId,
    }),
  };

  const response = fetch(`${API_BASE}/users/update/tooltip`, requestOptions);
  return await handleResponse(response);
};

// const sendEmailToAdmin = async (content) => {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify({
//       content,
//     }),
//   };

//   // const response = fetch(`${API_BASE}/users/sendEmail`);
// };

export const userService = {
  login: login,
  logout: logout,
  getUserById: getUserById,
  registerUser: registerUser,
  updateUser: updateUser,
  deleteUser: deleteUser,
  sendInvite: sendInvite,
  reset: reset,
  resetpwd: resetpwd,
  verifyUser: verifyUser,
  verifyUserId: verifyUserId,
  submitForVote,
  allowArtist,
  disallowArtist,
  allowSubmit,
  disallowSubmit,
  allowPriToken,
  disallowPriToken,
  updateVeriffUserId,
  resetPwd,
  updateTooltip,
  getUserByUrl,
  getUserArtworks,
  checkUserByEmail,
  purchaseArtworks,
  getSubmittedArtworks,
};
