import React from "react";
import { Navigate } from "react-router-dom";
import Cookie from "js-cookie";

export const ProtectedRoute = ({ children }) => {
  let token = Cookie.get("token");
  if (token !== undefined) {
    return <>{children}</>;
  } else {
    return <Navigate to="/logout" />;
  }
};

export const UnprotectedRoute = ({ children }) => {
  let token = Cookie.get("token");
  if (token === undefined) {
    return <>{children}</>;
  } else {
    return <Navigate to="/" />;
  }
};

export const ExceptionRoute = ({ children }) => {
  let token = Cookie.get("token");
  if (token === undefined) {
    return <Navigate to="/login" />
  } else {
    return <Navigate to="/" />;
  }
}
