import { authHeader, handleResponse } from "../utils/utils";
import { API_BASE } from "../config/constants";

const uploadArtwork = async (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  let response;
  if (id === "0") {
    response = fetch(
      `${API_BASE}/artwork/${id}/addArtworkByArtist`,
      requestOptions
    );
  } else response = fetch(`${API_BASE}/artwork/${id}`, requestOptions);
  return await handleResponse(response);
};

const uploadBillDoc = async (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  const response = fetch(`${API_BASE}/purchase/${id}`, requestOptions);
  return await handleResponse(response);
};

const updateMIA = async (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  const response = fetch(`${API_BASE}/artwork/${id}/mia`, requestOptions);
  return await handleResponse(response);
};

const updateSold = async (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  const response = fetch(`${API_BASE}/artwork/${id}/sold`, requestOptions);
  return await handleResponse(response);
};

const updateHistory = async (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  const response = fetch(
    `${API_BASE}/artwork/${id}/updateHistory`,
    requestOptions
  );
  return await handleResponse(response);
};

const updateStolen = async (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  const response = fetch(`${API_BASE}/artwork/${id}/stolen`, requestOptions);
  return await handleResponse(response);
};

const updatePolice = async (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  const response = fetch(`${API_BASE}/artwork/${id}/police`, requestOptions);
  return await handleResponse(response);
};

const updateInsurance = async (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  const response = fetch(`${API_BASE}/artwork/${id}/insurance`, requestOptions);
  return await handleResponse(response);
};

const votePurchase = async (id) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const response = fetch(`${API_BASE}/purchase/${id}`, requestOptions);
  return await handleResponse(response);
};

const voteArtist = async (id) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
    }),
  };

  const response = fetch(`${API_BASE}/users/vote`, requestOptions);
  return await handleResponse(response);
};

const disvoteArtist = async (id) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
    }),
  };

  const response = fetch(`${API_BASE}/users/disvote`, requestOptions);
  return await handleResponse(response);
};

const toggleArtist = async (id) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
    }),
  };

  const response = fetch(`${API_BASE}/users/toggle_recommend`, requestOptions);
  return await handleResponse(response);
};

const reportArtist = async (id, message) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
      message,
    }),
  };

  const response = fetch(`${API_BASE}/users/report`, requestOptions);
  return await handleResponse(response);
};

const changeCheckPurchase = async (id, status) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
      status,
    }),
  };

  const response = fetch(`${API_BASE}/users/check_purchase`, requestOptions);
  return await handleResponse(response);
};

const changeAdmin = async (id, status) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
      status,
    }),
  };

  const response = fetch(`${API_BASE}/users/change_admin`, requestOptions);
  return await handleResponse(response);
};

const changeSalePrice = async (id, price) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
      price,
    }),
  };

  const response = fetch(
    `${API_BASE}/artwork/change_saleprice`,
    requestOptions
  );
  return await handleResponse(response);
};

const sendReviewArtwork = async (id, status) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      status,
    }),
  };

  const response = fetch(`${API_BASE}/artwork/${id}`, requestOptions);
  return await handleResponse(response);
};

const deleteArtwork = async (id) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  const response = fetch(`${API_BASE}/artwork/${id}/delete`, requestOptions);
  return await handleResponse(response);
};

const getArtwork = async (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const response = fetch(`${API_BASE}/artwork/${id}`, requestOptions);
  return await handleResponse(response);
};

const getArtworks = async (
  type,
  filterkey = "",
  order = false,
  pagesize = 20,
  pagefrom = 0
) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      type,
      filterkey,
      order,
      pagesize,
      pagefrom,
    }),
  };

  const response = fetch(`${API_BASE}/artwork`, requestOptions);
  return await handleResponse(response);
};

const getNormalData = async (filter) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const response = fetch(`${API_BASE}/search/normal/${filter}`, requestOptions);
  return await handleResponse(response);
};

const getPurchase = async () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  const response = fetch(`${API_BASE}/purchase`, requestOptions);
  return await handleResponse(response);
};

const getUsers = async (
  type,
  options,
  filterkey = "",
  order = false,
  pagesize = 20,
  pagefrom = 0
) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      type,
      options,
      filterkey,
      order,
      pagesize,
      pagefrom,
    }),
  };

  const response = fetch(`${API_BASE}/users`, requestOptions);
  return await handleResponse(response);
};

const verifyArtwork = async (id) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
    }),
  };

  const response = fetch(`${API_BASE}/artwork`, requestOptions);
  return await handleResponse(response);
};

const disvoteArtwork = async (id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
    }),
  };

  const response = fetch(`${API_BASE}/artwork/disvote`, requestOptions);
  return await handleResponse(response);
};

const toggleRecommend = async (id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
    }),
  };

  const response = fetch(
    `${API_BASE}/artwork/toggle_recommend`,
    requestOptions
  );
  return await handleResponse(response);
};

const toggleCarousel = async (id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
    }),
  };

  const response = fetch(`${API_BASE}/artwork/toggle_carousel`, requestOptions);
  return await handleResponse(response);
};

const reportArtwork = async (id, message) => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      id,
      message,
    }),
  };

  const response = fetch(`${API_BASE}/artwork/disvote`, requestOptions);
  return await handleResponse(response);
};

const handleEditRequest = async (id, admin_id, status) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      admin_id,
      status,
    }),
  };

  const response = fetch(
    `${API_BASE}/artwork/${id}/handleEditRequest`,
    requestOptions
  );
  return await handleResponse(response);
};

const changeHiddenStatus = async (artwork_id, status) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      artwork_id,
      status,
    }),
  };

  const response = fetch(
    `${API_BASE}/artwork/changeHiddenStatus`,
    requestOptions
  );
  return await handleResponse(response);
};

const changeHiddenPriceStatus = async (artwork_id, status) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      artwork_id,
      status,
    }),
  };

  const response = fetch(
    `${API_BASE}/artwork/changeHiddenPriceStatus`,
    requestOptions
  );
  return await handleResponse(response);
};

//very strange
// const getArtworkPriceAnalysis = async() => {
//     const requestOptions = {
//         method: 'GET',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//     };
//     const response = fetch(`${API_BASE}/artwork/getArtworkPriceAnalysis`, requestOptions);

//     console.log("URL", `${API_BASE}/artwork/getArtworkPriceAnalysis`);
//     return await handleResponse(response);
// }

export const artService = {
  uploadArtwork,
  getArtwork,
  getArtworks,
  verifyArtwork,
  disvoteArtwork,
  reportArtwork,
  sendReviewArtwork,
  uploadBillDoc,
  getPurchase,
  votePurchase,
  getUsers,
  voteArtist,
  disvoteArtist,
  reportArtist,
  changeCheckPurchase,
  changeAdmin,
  changeSalePrice,
  getNormalData,
  toggleRecommend,
  toggleArtist,
  deleteArtwork,
  toggleCarousel,
  updateMIA,
  updateSold,
  updateHistory,
  updateStolen,
  updatePolice,
  updateInsurance,
  handleEditRequest,
  changeHiddenStatus,
  changeHiddenPriceStatus,
};
