import { createSlice } from '@reduxjs/toolkit';

//reducers
export const snackBarSlice = createSlice({
    name: 'snackBar',
    initialState: {
        opened: false,
        message: '',
        status: 'success'
    },
    reducers: {
        openSnackBar: (state, action) => {
            state.message = action.payload.message;
            if (!state.message.includes("Failed to fetch" || !state.message.includes("Load failed"))){
                state.opened = true;
                state.status = action.payload.status;
            }
        },
        closeSnackBar: (state) => {
            state.opened = false;
        },
    }
});


//actions
export const { openSnackBar, closeSnackBar } = snackBarSlice.actions;

//selectors
export const selectSnackState = (state) => state.snackBarState.opened;
export const selectSnackStatus = (state) => state.snackBarState.status;
export const selectSnackMessage = (state) => state.snackBarState.message;

export default snackBarSlice.reducer;