import { createSlice } from '@reduxjs/toolkit';
import { notificationService } from '../services/notification.service';
import { openSnackBar } from './snackBarReducer';

//reducers
export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        gettingNotification: false,
        getNotification: false,
        notifications: {},
        notification: {},
        showNotification: false,

        questionMark: true
    },
    reducers: {
        getRequest: (state) => {
            state.gettingNotification = true;
        },
        getSuccess: (state) => {
            state.gettingNotification = false;
            state.getNotification = true;
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        setNotification: (state, action) => {
            state.notification = action.payload;
        },
        showNotification: (state, action) => {
            state.showNotification = action.payload;
        },

        setQuestionMark: (state, action) => {
            state.questionMark = action.payload;
        }
    }
});

//actions
export const { getRequest, getSuccess, setNotifications, setNotification, showNotification, setQuestionMark } = notificationSlice.actions;

export const getNotification = (id) => async (dispatch) => {
    dispatch(getRequest);
    try {
        const respData = await notificationService.getNotification(id);
        dispatch(setNotifications(respData));
        dispatch(getSuccess());
    } catch (error) {
        dispatch(openSnackBar({ message: error.message, status: 'error' }))
    }
}

export const updateNotification = (notification) => (dispatch) => {
    try {
        dispatch(setNotification(notification));
    } catch (error) {
        dispatch(openSnackBar({ message: error.message, status: 'error' }))
    }
}

export const updateNotificationShow = (show) => (dispatch) => {
    try {
        dispatch(showNotification(show));
    } catch (error) {
        dispatch(openSnackBar({ message: error.message, status: 'error' }))
    }
}

export const readNotification = (id, user_id) => async (dispatch) => {
    dispatch(getRequest);
    try {
        await notificationService.readNotification(id);
        const respData = await notificationService.getNotification(user_id);
        dispatch(setNotifications(respData));
        dispatch(getSuccess());
    } catch (error) {
        dispatch(openSnackBar({ message: error.message, status: 'error' }))
    }
}

//selectors
export const selectNotifications = (state) => state.notificationState.notifications;
export const selectNotification = (state) => state.notificationState.notification;
export const selectNotificationShow = (state) => state.notificationState.showNotification;

export const selectQuestionMark = (state) => state.notificationState.questionMark;

export default notificationSlice.reducer;